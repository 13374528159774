import { rgba } from "polished";

export const colors = {
  pink100: "#D6006D",
  lightMagenta: "#de008e",
  magenta: "#c6007f",
  paleGrey: "#f5faff",
  lightPink: "#f5e1e3",
  palePink: "#fff5f1",
  wheat: "#ffe27a",
  green: "#79e97a",
  lightGreen: "#b9fcb9",
  watermelon: "#f2576a",
  pink: "#c6007f",
  lavenderBlush: "#fff1f6",
  blue: "#3979D6",
  lightBlue: "#39d6c0",

  aquaSqueeze: "#ECF6F4",
  aquaSqueezeLight: "#e2f6f2",
  oldLace: "#FDF7E0",
  sauvignon: "#FFF5F1",
  sauvignonLight: "#fae7e0",
  concrete: "#F3F3F3",
  aliceBlue: "#E9F2FF",

  white: "#ffffff",
  gray025: "#fafafa",
  gray050: "#f3f3f3",
  gray100: "#ebebeb",
  silver: "#bebebe",
  gray150: "#dcd3d2",
  gray200: "#d4d4d4",
  gray300: "#b4b4b4",
  gray400: "#9b9b9b",
  gray500: "#595959",
  gray600: "#4a4a4a",
  gray700: "#7d7d7d",
  gray800: "#cecece",
  gray900: "#f9f9f9",
  black: "#262626",
  nearBlack: "#454545",
  darkBlack: "#000000",
  cinnabar: "#e13838",
  alto: "#dfdfdf",
  darkGray: "#979797",
  paleOrange: "#FDF7E0",
  fauxAliceBlue: "#E9F2FF",
  hintOfRed: "#f5f2f2",
  pomegranate: "#ee2b2b",

  transparentGray: rgba("#979797", 0.3),
  transparentGray100: rgba("#EBEBEB", 0.3),

  bgColor: "#F9F4F4",

  accent5: "#D6006D",
  accent85: "#FFA3D2",
  accent90: "#F8D3E7",
  accent95: "#FCEBF4",
  accent100: "#FDF2F8",

  neutral15: "#B0B0B0",
  neutral20: "#D4D4D4",
  neutral95: "#FAFAFA",
  neutral90: "#F5F5F5",

  success0: "#31BD2E",
  error0: "#E83134",
  corpAccentGold: "#A0A25D",
};

export const tableSelectColors = {
  green: colors.aquaSqueeze,
  greenLight: colors.aquaSqueezeLight,
  yellow: colors.oldLace,
  red: colors.sauvignon,
  redLight: colors.sauvignonLight,
  gray: colors.concrete,
  blue: colors.aliceBlue
};
