import React from "react";
import CircularProgress from "@mui/material/CircularProgress";
import { Backdrop } from "@mui/material";
import styled, { css } from "styled-components";
import { styled as styledMaterial } from "@mui/material/styles";

const BackdropLoaderContainer = styledMaterial(Backdrop)(
  ({ theme }) => `
    background-color: rgba(255, 255, 255, 0.68);
    z-index: ${theme.zIndex.modal};
  `
);

const AbsoluteLoaderContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: ${props => props?.top + 'px' || 0};
    bottom: 0;
    right: 0;
    z-index: ${props => props?.zIndex || 100};
    background: ${props => props.withoutBackground ? 'transparent' : "rgba(255, 255, 255, 0.68)"};
    pointer-events: ${props => props.disablePointerEvents ? "none" : "auto"};
`;

export const BlockLoaderContainer = styled(AbsoluteLoaderContainer)`
    ${props => props.transparent ? css`background: transparent;` : null}
    position: relative;
    margin: 16px 0;
    ${props => props.withoutMargin && "margin: 0;"};
    ${props => props.customHeight && `height: ${props.customHeight};`};
    z-index: 10;
`;

export const AbsoluteLoader = ({ size = 50, zIndex = 100, withoutBackground, disablePointerEvents, top }) => {
  return (
    <AbsoluteLoaderContainer top={top} zIndex={zIndex} withoutBackground={withoutBackground} disablePointerEvents={disablePointerEvents}>
      <CircularProgress size={size} />
    </AbsoluteLoaderContainer>
  );
};

export const BlockLoader = ({ transparent, size = 50, withoutMargin, customHeight }) => {
  return (
    <BlockLoaderContainer transparent={transparent} withoutMargin={withoutMargin} customHeight={customHeight}>
      <CircularProgress size={size} />
    </BlockLoaderContainer>
  );
};

export const BackdropLoader = ({ isOpen = false, size = 50 }) => {
  return (
    <BackdropLoaderContainer open={isOpen}>
      <CircularProgress size={size} />
    </BackdropLoaderContainer>
  )
}
